import React from 'react';
import './Maintenanceprompt.scss';

const MaintenancePrompt = () => {
    return (
        <div class="maintenace__container">
        <div class="message">
        Our program will be unavailable for maintenance between 10:00 p.m. and 2:00 a.m. (MDT) on Sunday, October 13.<br></br>
        If you have any questions or concerns, please call our support team at 587-341-4042.
        <br></br>       
        </div>
      </div>
    );
};
export default MaintenancePrompt;
